"use client"
import axios from "axios";
import { FormEvent, useEffect, useRef, useState } from "react";


export function Newsletter() {
  const REGEX_EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const form: any = useRef();
  const [sended, setSended] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<{emailError: boolean;}>({emailError: true});

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setSended("loading");
    const body = {
      email: email
    }
    axios.post('/api/newsletter', body)
    .then(({data}) => {
      setSended("send");
      setError({ emailError: true });
      // setTimeout(() => setSended(""), 3000);
    })
    .catch((err) => {
      const errorStatus = err.response.status;
      if (errorStatus === 400) {
        setSended("email-already-exists")
      } else {
        setSended("error")
      }
    });
  }

  useEffect(() => {
    setError({ ...error, emailError: !REGEX_EMAIL.test(email) });
  }, [email, error.emailError]);


  return (
    <div className="home3-newsletter-section mb-110">
      <div className="col-10 mx-auto mt-5">
        <form className="newsletter-wrapper"
          onSubmit={handleSubmit}
          ref={form}>
          <div className="row g-lg-4 gy-5 align-items-center">
            <div className="col-lg-7 col-md-6 wow animate fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms"
            style={{
              visibility: 'visible',
              animationDuration: '1500ms',
              animationDelay: '200ms'
            }}>
              <div className="section-title white">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                    <g>
                      <circle cx="5" cy="5" r="5"></circle>
                    </g>
                  </svg>
                  Newsletter
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
                    <g>
                      <circle cx="5" cy="5" r="5"></circle>
                    </g>
                  </svg>
                </span>
                <h2>
                  Recibe notificaciones de la comunidad.  
                </h2>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 wow animate fadeInRight" data-wow-delay="200ms" data-wow-duration="1500ms"
            style={{
              visibility: 'visible',
              animationDuration: '1500ms',
              animationDelay: '200ms'
            }}>
              <div className="newsletter-form-area">
                <h6>Suscribirme</h6>
                <div className="form-inner">
                    <input 
                    placeholder="Correo electrónico"
                    name="from_email"
                    onChange={(e) => {
                      setSended("");
                      setEmail(e.target.value.toLowerCase())
                    }}
                    typeof="email"
                    value={email.toLowerCase()} />
                    <button className="primary-btn2" type="submit" disabled={
                        error.emailError ||
                        sended === "loading"
                      }>
                      <i className="bi bi-arrow-right"></i>
                    </button>
                </div>
                {error.emailError && email && (
                  <p className="text-danger mt-1 mb-0">
                    <small>Correo electrónico inválido</small>
                  </p>
                )}
                {sended === "email-already-exists" && (
                  <p className="text-danger mt-1 mb-0">
                    <small>El correo ya se encuentra en la lista</small>
                  </p>
                )}
                {sended === "send" && (
                  <p className="text-white mt-1 mb-0">
                    <small>Ya eres parte del newsletter de la comunidad. Bienvenid@ 👋</small>
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}