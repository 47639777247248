"use client"

import React from 'react';
import Services from '@/components/Home/Services';
import Culture from '@/components/Home/Culture';
import Companies from '@/components/Home/Companies';
import { Contact } from '@/components/Home/Contact';
import { Newsletter } from '@/components/Home/Newsletter';
import { LastArticles } from '@/components/Home/LastArticles';



const HomePageComponent = () => {

  return (
    <>
        <Companies />
        <Culture />
        <div id="beneficios">
          <Services />
        </div>
        <LastArticles />
        <div id="suscripcion">
          <Newsletter />
        </div>
        <div id="contacto">
          <Contact />
        </div>
    </>
  );
}


export default HomePageComponent;