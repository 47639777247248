import(/* webpackMode: "eager" */ "/app/app/(not-auth)/globals.css");
import(/* webpackMode: "eager" */ "/app/app/home/content.tsx");
import(/* webpackMode: "eager" */ "/app/app/nav.tsx");
import(/* webpackMode: "eager" */ "/app/components/button-up.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/public/img/home1/footer-logo-bg-dark.png");
import(/* webpackMode: "eager" */ "/app/public/img/home4/argentina-flag.jpg");
import(/* webpackMode: "eager" */ "/app/public/img/home4/home4-banner-img-dark-2.png");
import(/* webpackMode: "eager" */ "/app/public/img/home4/home4-banner-img-dark.png");
import(/* webpackMode: "eager" */ "/app/public/img/LogoProgramadoresArgentinaByW.png")